/* relative mx-6  w-full sm:w-[460px] bg-[#f2f2f2] shadow-2xl rounded-md p-6 sm:px-8 pb-10 */

.page{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0px 24px;
    width: 102vw;
    background-color: #fff;
    padding: 20px;
}

.tbutton:hover {
    background-color: #eb9292 !important;
    transform: scale(0.9);
}

.pbutton:hover {
    background-color: #eb9292 !important;
    transform: scale(0.9);
    
}